import React from 'react';
import './Header.css';
import logo from './logo.jpg'; // Make sure to have your logo image in the src directory

const Header = () => {

const changeLanguage = () =>{
  if(localStorage.getItem("language")==="Hindi"){
    localStorage.setItem("language", "English");
  }
  else{
    localStorage.setItem("language", "Hindi");
  }
  location.reload()
}

  return (
    <header className="header">
      <div className="left-side">
        <img src={logo} alt="DnFins Logo" className="logo" />
      </div>
      <div className="right-side">
      {localStorage.getItem("language")==="Hindi"?<button className="buttononHeader">सेवा अनुरोध लॉगिन</button>:
      <button className="buttononHeader">Service Request Login</button>}
        <button className="buttononHeader" onClick={()=>changeLanguage()}>{localStorage.getItem("language")==="Hindi"?"English":"हिंदी"}</button>
      </div>
    </header>
  );
};

export default Header;