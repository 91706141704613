import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ReviewsCarousel.css'; // Custom CSS for additional styling

const reviews = [
  {
    name: "DR. SANDEEP SINGHAL",
    text: "All field and management team members were cooperative, punctual and knowledgable about there areas of functioning. Pooja Ji and Arvindji were always a call away in case of any clarification or help. I will highly recommend DN Financials to others. As it’s loan ki pathshala, I have learnt lot of things on the way of loan processing. All the best to complete team for all future endeavours.",
  },
  {
    name: "DR. AJAY BEDI",
    text: "The professionalism and confidence shown by the DN Financial Services team convinced me to choose them for my financial needs. Their approach was both reassuring and thorough, providing clear guidance throughout the process. I appreciate their commitment to maintaining high standards and would recommend their services to others. Their dedication to client satisfaction and efficient service truly sets them apart."
  }  
  // Add more reviews as needed
];

const ReviewsCarousel = () => {
  return (
    <div className="reviews-carousel">
      <br/>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
      >
        {reviews.map((review, index) => (
          <div key={index} className="review-slide">
            <h2>{review.name}</h2>
            <p>{review.text}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ReviewsCarousel;
